.ChatBot {
  line-height: 1.5em;
  height: 100vh;  // Ensure the chat bot takes full viewport height
  overflow: hidden;  // Prevent page scrolling

  .chat-bot-container {
    width: 100%;
    max-width: 600px;
    height: 100vh;  // Adjusted to use full height
    background-color: #f5f5f7;
    border-radius: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;  // Ensure fixed positioning
    top: 0;  // Align to the top of the viewport
    left: 0;  // Align to the left of the viewport
    z-index: 1000;

    @media (max-width: 768px) {
      height: 100vh;
      width: 100%;
    }

    &__is-open {
      display: flex;
    }

    .chat-bot-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #007aff;
      color: white;
      font-size: 18px;
      font-weight: 400;
      text-align: end;

      & strong {
        text-align: start;
      }
    }

    .chat-bot-body {
      flex-grow: 1;
      overflow-y: auto;
      padding: 10px;
      background-color: #f5f5f7;

      .chat-bot-messages {
        padding-top: 32px;
        flex-grow: 1;
        overflow-y: auto;
      }
    }

    .chat-bot-footer {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: #ffffff;
      border-top: 1px solid #ddd;
      position: fixed;  // Fixed positioning for the footer
      bottom: 0;  // Align to the bottom of the viewport
      width: 100%;  // Ensure it spans the full width
      padding-bottom: 88px;

      .chat-bot-footer__input {
        flex-grow: 1;
        min-height: 36px;
        padding: 8px 12px;
        background: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 18px;
        outline: none;
        font-size: 16px;
        resize: none;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 1.5;
        }
      }

      .chat-bot-footer__send-button {
        padding: 8px 12px;
        margin-left: 10px;
        margin-right: 16px;
        background-color: #007aff;
        color: #fff;
        border: none;
        border-radius: 18px;
        cursor: pointer;
        font-size: 16px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  .chat-bot-message {
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 16px !important;
      line-height: 1.5;
    }

    .chat-bot-message__content {
      padding: 10px;
      border-radius: 18px;
      max-width: 75%;
      word-wrap: break-word;
    }

    &__is-user {
      justify-content: flex-end;

      .chat-bot-message__content {
        background-color: #007aff;
        color: #fff;
        border-bottom-right-radius: 0;
      }
    }

    &__is-bot {
      justify-content: flex-start;

      .chat-bot-message__content {
        background-color: #e5e5ea;
        color: #000;
        border-bottom-left-radius: 0;
      }
    }
  }

  a {
    color: #007aff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
